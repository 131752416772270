/*
__Seed builder__v0.2.0
  Extended module
*/

import _Users from 'seed/actions/users';

class Users extends _Users 
{
  constructor()
  {
    const fetch = []
    super(fetch)
  }
}

export default Users;