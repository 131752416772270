/*
__Seed builder__v0.2.0
  (Read_only) Builder helper
*/

export const GET_LIST = "GET_LIST";
export const GET_DETAILS = "GET_DETAILS";
export const POST = "POST";
export const PUT = "PUT";
export const DELETE = "DELETE";
export const RESTART = "RESTART";